import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import GatsbyBackgroundImage from 'gatsby-background-image';

const BackgroundImage = ({ src, ...rest }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderBackgroundImage: allFile(
        filter: { sourceInstanceName: { eq: "images" } }
      ) {
        edges {
          node {
            publicURL
            relativePath
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  const image = data.placeholderBackgroundImage.edges.find(element => {
    return element.node.relativePath === src;
  });

  if (!image) {
    return null;
  }

  if (image.node.childImageSharp) {
    return (
      <GatsbyBackgroundImage
        {...rest}
        css={{
          width: '100%',
          height: '100%'
        }}
        fluid={image.node.childImageSharp.fluid}
      />
    );
  }

  return (
    <div
      {...rest}
      style={{ backgroundImage: `url(${image.node.publicURL})` }}
    />
  );
};

export default BackgroundImage;
